.pswp-custom-preloader
	display: inline-flex
	align-items: center
	justify-content: center
	position: absolute
	left: 0
	bottom: calc(var(--pswp-max-gutters-vertical) * 1px)
	z-index: 50 !important
	opacity: 0
	visibility: hidden
	+trans1(opacity)
	svg
		width: 30px
		height: 30px
		z-index: 10000
		animation: rotate 2s linear infinite
		transform-origin: center center
		color: var(--color-accent)
		circle
			stroke: currentColor
			stroke-width: 2px
			stroke-dasharray: 150, 200
			stroke-dashoffset: -150
			animation: dash 1.5s ease-in-out infinite
			stroke-linecap: round
.pswp__preloader--active
	&.pswp-custom-preloader
		opacity: 1
		visibility: visible
