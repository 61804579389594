.pswp-custom-caption
	position: absolute
	left: 0
	right: 0
	bottom: 0
	padding-left: var(--gutter-horizontal)
	padding-right: var(--gutter-horizontal)
	text-align: center
	background-color: #000
	a
		color: var(--color-accent)
.pswp-custom-caption__content
	display: inline-block
	max-width: 600px
	@extend .ui-element
	padding: 20px 0
