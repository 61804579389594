.marquee-crossing-lanes__wrapper-button
	position: absolute
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)
	z-index: 50
.marquee-crossing-lanes__lane
	position: relative
	transform-origin: center center
	margin-left: -2rem
	margin-right: -2rem
	width: auto
	outline: 2px solid transparent // fix jagged edges in Safari
	zoom: 1.005
.marquee-crossing-lanes__lane_rotate-1
	transform: rotateZ(-3deg) translate3d(0, 50%, 0)
	z-index: 50
.marquee-crossing-lanes__lane_rotate-2
	transform: rotateZ(3deg) translate3d(0, -50%, 0)
	z-index: 40

+max-screen-xl
	.marquee-crossing-lanes__lane_rotate-1
		transform: rotateZ(-4deg) translate3d(0, 50%, 0)
	.marquee-crossing-lanes__lane_rotate-2
		transform: rotateZ(4deg) translate3d(0, -50%, 0)
	.marquee-crossing-lanes__inner
		padding-top: 1.5rem
		padding-bottom: 1.5rem

+max-screen-lg
	.marquee-crossing-lanes__lane_rotate-1
		transform: rotateZ(-5deg) translate3d(0, 50%, 0)
	.marquee-crossing-lanes__lane_rotate-2
		transform: rotateZ(5deg) translate3d(0, -50%, 0)
