.custom-modal
	--duracion: .25s
	--offset: 0, 50px
	position: fixed
	z-index: 9999
	width: 100vw
	height: 100%
	display: none
	background: rgba(#000,0.9)
	//backdrop-filter: blur(6px)
	overflow-y: auto
	.container
		padding: 0
		display: flex
		min-height: 100%
		background: var(--color-light-1)
		+fluid('margin-top', 0, 80)
		+fluid('margin-bottom', 0, 80)
		max-width: 1300px
		@media screen and (max-width: $xxl)
			max-width: calc( 100% - 60px  )
		@media screen and (max-width: $sm)
			max-width: 100%
			margin-top: 0
			margin-bottom: 0
		.modal-center
			display: flex
			align-items: center	
			flex-direction: column
			width: 100%
		.p-medium
			@media screen and (max-width: $lg)
				padding: 50px 20px





	&.show
		display: block
		animation: show-modal var(--duracion) ease forwards
		section
			animation: reset-translate var(--duracion) ease forwards
		&.hidde
			animation: hidde-modal var(--duracion) ease forwards
			section
				animation: set-translate var(--duracion) ease forwards
	.close-btn
		position: absolute
		font-size: 3rem
		+fluid('right', 20, 30)
		+fluid('top', 20, 30)
		background: none
		border: none
		appearance: none
		color: var(--color-red)
		z-index: 99
		cursor: pointer
		@media screen and (max-width: $md)
			font-size: 2.5rem
			
	
@keyframes show-modal 
	from
		opacity: 0
	to
		opacity: 1

@keyframes reset-translate
	from
		transform: translate(var(--offset))
	to
		transform: translate(0)

@keyframes hidde-modal 
	from
		opacity: 1
	to
		opacity: 0



