.widget_categories
	ul
		li
			display: flex !important
			justify-content: space-between
			align-items: center
			flex-wrap: wrap
			font-size: 16px
			font-weight: 600
			span
				@extend .subheading
				line-height: 1
