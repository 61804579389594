.hover-scroll
	--container-height: 66vh
.hover-scroll__inner
	height: var(--container-height)
	overflow: hidden
.hover-scroll__link
	position: relative
	display: block
	width: 100%
	height: 100%
	overflow: hidden
	&:hover
		.hover-scroll__element
			transform: translateY(calc(-100% + var(--container-height)))
.hover-scroll__element
	// transform: translateZ(0) // Safari fix
	transition: transform 5s ease
	img
		// transform: translateZ(0) // Safari fix
