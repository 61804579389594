.auto-scroll-next
	overflow: hidden
	position: relative
	z-index: 100
.auto-scroll-next__label
	position: absolute !important
	top: 0
	left: 0
	right: 0
	width: 100%
	+trans1(opacity)
	opacity: 1
	visibility: visible
.auto-scroll-next__heading
	+trans1
.auto-scroll-next__link
	display: block
	overflow: initial !important
		// +trans1(color)
	&:hover
		*
			color: currentColor
.auto-scroll-next__wrapper-media
	display: inline-flex
	align-items: center
	justify-content: center
	position: absolute
	top: 50%
	left: var(--gutter-horizontal)
	right: var(--gutter-horizontal)
	max-width: calc(100% - var(--gutter-horizontal) * 2)
	max-height: 100%
	margin: 0 auto
	transform: translateY(-50%)
	pointer-events: none
	*:not(.auto-scroll-next__inner-media)
		pointer-events: none
.auto-scroll-next__header
	pointer-events: none
	cursor: pointer
	overflow: initial !important
	// *
	// 	pointer-events: all
.auto-scroll-next__lane
	+trans1(opacity)
.auto-scroll-next__inner
	z-index: 200 !important
.auto-scroll-next__inner-media
	overflow: hidden
	display: inline-flex
	justify-content: center
	align-items: stretch
	overflow: hidden
	// width: 720px
	height: 60vh
	max-width: 100%
	pointer-events: all
	img
		width: 100%
		height: 100%
		max-height: 100%
		max-width: 100%
		object-fit: cover
		object-position: center center
.auto-scroll-next__inner-media:not(.js-ajax-transition-clone)
	img
		width: auto
		height: 100%
.auto-scroll-next__mask-media
	display: block
	height: 100%
	width: auto
	overflow: hidden
.auto-scroll-next__progress-line
	position: absolute
	left: 0
	right: 0
	bottom: 0
	width: 100%
	height: 1px
	background-color: currentColor
	z-index: 50
	transform: scaleX(0)
	transform-origin: left center
