\:root
	--fix-slider: 70px
	
#slider
	height: calc( 100vh - var(--fix-slider))
	.section-fullheight__inner
		height: 100%
	.swiper
		overflow: visible !important
	.slider-fullpage-backgrounds__section	
		overflow: visible !important


.mask-banner-1
	-webkit-mask-image: url('../img/mask/banner-1.svg')
	mask-image: url('../img/mask/banner-1.svg')
	mask: url('../img/mask/banner-1.svg')
	-webkit-mask-size: cover
	mask-size: cover
	-webkit-mask-repeat: no-repeat
	mask-repeat: no-repeat
	-webkit-mask-position: 15% 0%
	mask-position: 15% 0%
	@media screen and (max-width: $xxl)
		mask-position: 15% 0%
	@media screen and (max-width: $sm)
		mask-image: none	


.section-fullheight-box	
	z-index: 99 !important
	position: absolute
	bottom: calc(-1 * var(--fix-slider) + 20px)
	+fluid('width', 400, 700)
	@media screen and (max-width: $sm)
		width: calc(100% - 40px)

.mask-banner-2
	-webkit-mask-image: url('../img/mask/banner-2.svg')
	mask-image: url('../img/mask/banner-2.svg')
	mask: url('../img/mask/banner-2.svg')
	-webkit-mask-size: cover
	mask-size: cover
	-webkit-mask-repeat: no-repeat
	mask-repeat: no-repeat
	-webkit-mask-position: left
	mask-position: left
	background: red
	padding: 30px 60px 40px 40px
	@media screen and (max-width: $sm)
		padding: 20px 40px 50px 20px
