.rotating-button
	display: inline-flex
.rotating-button__link
	display: inline-flex
	align-items: center
	justify-content: center
	text-align: center
	position: relative
	border-radius: 50%
	*
		pointer-events: none
.rotating-button__icon
	position: absolute
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)
	fill: currentColor
.rotating-button__dot
	color: var(--color-accent)
	pointer-events: none
	--size-min: 20
	--size-max: 25
	position: relative
	+fluid('width', var(--size-min), var(--size-max))
	+fluid('height', var(--size-min), var(--size-max))
	box-shadow: 0px 0px 0px 1px var(--color-border-opacity) inset
	display: inline-flex
	align-items: center
	border-radius: 100%
	vertical-align: middle
	z-index: 50
	svg
		display: block
		position: absolute
		top: 50%
		left: 50%
		transform: translate(-50%, -50%)
		margin: auto
		width: 100%
		height: 100%
		border-radius: 100%
		stroke-width: 4px
		pointer-events: none
		.circle
			stroke: currentColor

.rotating-button__label
	white-space: nowrap

+max-screen-md
	.rotating-button__label
		font-size: 10px
