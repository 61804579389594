.pswp-custom-button_close
	order: 10
	&:hover
		.pswp-custom-button__close-label-close
			transform: translateY(-100%)
			opacity: 0
		.pswp-custom-button__close-label-hover
			transform: translateY(0%)
			opacity: 1
.pswp-custom-button__close-label-close
	position: relative
	transform: translateY(0%)
	opacity: 1
	+trans1
.pswp-custom-button__close-label-hover
	position: absolute
	top: 0
	right: 0
	transform: translateY(100%)
	opacity: 0
	+trans1
.pswp-custom-button__close-wrapper-lines
	@extend .header__burger-wrapper-lines
.pswp-custom-button__close-line
	@extend .header__burger-line
	&:nth-of-type(1)
		transform: scaleX(1) rotate(45deg) translate(2px, 2px)
	&:nth-of-type(2)
		transform: scaleX(1) rotate(-45deg) translate(2px, -2px)
	&:nth-of-type(3)
		transform-origin: right center
		transform: scaleX(0)
