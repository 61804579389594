/* SWIPPER */
.swiper-pagination-bullet-active
	background: var(--color-red)
.swipper-padding
	.swiper-slide:first-child
		+fluid('margin-left', 20, 160)
	.swiper-slide:last-child
		maring-right: 20px

.swiper-container
	overflow: visible
	+fluid('padding-left', 0, 160)
	+fluid('padding-right', 15, 30)

.swiper-style-1
	.swiper-slide
		+trans1
		position: relative
	.swiper-slide:not(.swiper-slide-active)
		transform: scale(0.9)
		opacity: 0.6
		
		

.swiper-style-2
	.swiper-pagination
		max-width: 39%
		position: absolute
		left: 0
		+fluid('bottom', 70, 100)
		@media screen and (max-width: $sm)
			position: relative
			bottom: 80px
			max-width: 100%

	.swiper-pagination-bullet
		background: #fff	
		@media screen and (max-width: $sm)
			background: #000