.slider-images
	position: relative
	width: 100%
	overflow: hidden
	--slider-min-height-vh: 50
	--slider-max-height-vh: 70
	@include fluid('--slider-height-vh', var(--slider-min-height-vh), var(--slider-max-height-vh), $min_width, $max_width, 1, 'vh')
	height: var(--slider-height-vh)
.slider-images__lane
	display: flex
	flex-wrap: nowrap
	align-items: center
	width: 100%
	height: 100%
	overflow-x: hidden
.slider-images__item
	height: 100%
	max-width: calc(100vw - 4 * var(--gutter-horizontal))
	// height: 100%
