.no-horizontal-scroll
	.no-hs-text-start
		text-align: left !important
	.no-hs-text-center
		text-align: center !important
	.no-hs-text-end
		text-align: right !important

	.no-hs-align-items-center
		align-items: center !important
	.no-hs-d-block
		display: block !important
	.no-hs-d-flex
		display: flex !important
	.no-hs-d-inline-flex
		display: inline-flex !important
	.no-hs-d-none
		display: none !important

	.no-hs-pt-header-height
		padding-top: var(--header-height)
	.no-hs-pb-header-height
		padding-bottom: var(--header-height)
	.no-hs-py-header-height
		padding-top: var(--header-height)
		padding-bottom: var(--header-height)
	.no-hs-header-height
		height: var(--header-height)

	.no-hs-mt-0
		margin-top: 0 !important
	.no-hs-mb-0
		margin-bottom: 0 !important
	.no-hs-me-0
		margin-right: 0 !important
	.no-hs-ms-0
		margin-left: 0 !important
	.no-hs-my-0
		margin-top: 0 !important
		margin-bottom: 0 !important
	.no-hs-mx-0
		margin-left: 0 !important
		margin-right: 0 !important
	.no-hs-m-0
		margin: 0 !important

	.no-hs-pt-0
		padding-top: 0 !important
	.no-hs-pb-0
		padding-bottom: 0 !important
	.no-hs-pe-0
		padding-right: 0 !important
	.no-hs-ps-0
		padding-left: 0 !important
	.no-hs-py-0
		paddipg-top: 0 !important
		padding-bottom: 0 !important
	.no-hs-px-0
		padding-left: 0 !important
		padding-right: 0 !important
	.no-hs-p-0
		padding: 0 !important

	+min-screen-md
		.no-hs-pb-lg-0
			padding-bottom: 0 !important

	+max-screen-lg
		.no-hs-max-lg-pt-0
			padding-top: 0 !important
		.no-hs-max-lg-px-0
			padding-left: 0 !important
			padding-right: 0 !important
		.no-hs-max-lg-py-0
			padding-top: 0 !important
			padding-bottom: 0 !important
		.no-hs-max-lg-pb-0
			padding-bottom: 0 !important
		.no-hs-max-lg-p-0
			padding: 0 !important

		.no-hs-max-lg-mt-0
			margin-top: 0 !important
		.no-hs-max-lg-mx-0
			margin-left: 0 !important
			margin-right: 0 !important
		.no-hs-max-lg-my-0
			margin-top: 0 !important
			margin-bottom: 0 !important
		.no-hs-max-lg-mb-0
			margin-bottom: 0 !important
		.no-hs-max-lg-m-0
			margin: 0 !important

	+max-screen-sm
		.no-hs-max-sm-pt-0
			padding-top: 0 !important
		.no-hs-max-sm-pb-0
			padding-bottom: 0 !important
		.no-hs-max-sm-px-0
			padding-left: 0 !important
			padding-right: 0 !important
		.no-hs-max-sm-py-0
			padding-top: 0 !important
			padding-bottom: 0 !important

		.no-hs-max-sm-mt-0
			margin-top: 0 !important
		.no-hs-max-sm-mx-0
			margin-left: 0 !important
			margin-right: 0 !important
		.no-hs-max-sm-my-0
			margin-top: 0 !important
			margin-bottom: 0 !important
		.no-hs-max-sm-mb-0
			margin-bottom: 0 !important
		.no-hs-max-sm-m-0
			margin: 0 !important

.has-horizontal-scroll
	.hs-full-height
		position: relative !important
		display: inline-flex !important
		align-items: center !important
		justify-content: center !important
		width: auto !important
		height: 100% !important
		max-width: unset !important

	.no-hs-d-block
		display: none !important
	.no-hs-d-flex
		display: none !important
	.no-hs-d-inline-flex
		display: none !important
	.no-hs-d-none
		display: block !important

	.hs-pt-header-height
		padding-top: var(--header-height)
	.hs-pb-header-height
		padding-bottom: var(--header-height)
	.hs-py-header-height
		padding-top: var(--header-height)
		padding-bottom: var(--header-height)
	.hs-header-height
		height: var(--header-height)

	.hs-mt-header-height
		margin-top: var(--header-height)
	.hs-mb-header-height
		margin-bottom: var(--header-height)
	.hs-my-header-height
		margin-top: var(--header-height)
		margin-bottom: var(--header-height)

	.hs-vh-100
		height: 100vh !important

	.hs-col
		flex: 1 0 0%

	.row-hs-cols-auto > *
		flex: 0 0 auto
		width: auto

	.row-hs-cols-1 > *
		flex: 0 0 auto
		width: 100%

	.row-hs-cols-2 > *
		flex: 0 0 auto
		width: 50%

	.row-hs-cols-3 > *
		flex: 0 0 auto
		width: 33.3333333333%

	.row-hs-cols-4 > *
		flex: 0 0 auto
		width: 25%

	.row-hs-cols-5 > *
		flex: 0 0 auto
		width: 20%

	.row-hs-cols-6 > *
		flex: 0 0 auto
		width: 16.6666666667%

	.hs-col-auto
		flex: 0 0 auto
		width: auto

	.hs-col-1
		flex: 0 0 auto
		width: 8.33333333%

	.hs-col-2
		flex: 0 0 auto
		width: 16.66666667%

	.hs-col-3
		flex: 0 0 auto
		width: 25%

	.hs-col-4
		flex: 0 0 auto
		width: 33.33333333%

	.hs-col-5
		flex: 0 0 auto
		width: 41.66666667%

	.hs-col-6
		flex: 0 0 auto
		width: 50%

	.hs-col-7
		flex: 0 0 auto
		width: 58.33333333%

	.hs-col-8
		flex: 0 0 auto
		width: 66.66666667%

	.hs-col-9
		flex: 0 0 auto
		width: 75%

	.hs-col-10
		flex: 0 0 auto
		width: 83.33333333%

	.hs-col-11
		flex: 0 0 auto
		width: 91.66666667%

	.hs-col-12
		flex: 0 0 auto
		width: 100%

	+min-screen-md
		.hs-pb-lg-0
			padding-bottom: 0 !important

	@media (min-width: 576px)
		.hs-col-sm
			flex: 1 0 0%

		.row-hs-cols-sm-auto > *
			flex: 0 0 auto
			width: auto

		.row-hs-cols-sm-1 > *
			flex: 0 0 auto
			width: 100%

		.row-hs-cols-sm-2 > *
			flex: 0 0 auto
			width: 50%

		.row-hs-cols-sm-3 > *
			flex: 0 0 auto
			width: 33.3333333333%

		.row-hs-cols-sm-4 > *
			flex: 0 0 auto
			width: 25%

		.row-hs-cols-sm-5 > *
			flex: 0 0 auto
			width: 20%

		.row-hs-cols-sm-6 > *
			flex: 0 0 auto
			width: 16.6666666667%

		.hs-col-sm-auto
			flex: 0 0 auto
			width: auto

		.hs-col-sm-1
			flex: 0 0 auto
			width: 8.33333333%

		.hs-col-sm-2
			flex: 0 0 auto
			width: 16.66666667%

		.hs-col-sm-3
			flex: 0 0 auto
			width: 25%

		.hs-col-sm-4
			flex: 0 0 auto
			width: 33.33333333%

		.hs-col-sm-5
			flex: 0 0 auto
			width: 41.66666667%

		.hs-col-sm-6
			flex: 0 0 auto
			width: 50%

		.hs-col-sm-7
			flex: 0 0 auto
			width: 58.33333333%

		.hs-col-sm-8
			flex: 0 0 auto
			width: 66.66666667%

		.hs-col-sm-9
			flex: 0 0 auto
			width: 75%

		.hs-col-sm-10
			flex: 0 0 auto
			width: 83.33333333%

		.hs-col-sm-11
			flex: 0 0 auto
			width: 91.66666667%

		.hs-col-sm-12
			flex: 0 0 auto
			width: 100%

	@media (min-width: 768px)
		.hs-col-md
			flex: 1 0 0%

		.row-hs-cols-md-auto > *
			flex: 0 0 auto
			width: auto

		.row-hs-cols-md-1 > *
			flex: 0 0 auto
			width: 100%

		.row-hs-cols-md-2 > *
			flex: 0 0 auto
			width: 50%

		.row-hs-cols-md-3 > *
			flex: 0 0 auto
			width: 33.3333333333%

		.row-hs-cols-md-4 > *
			flex: 0 0 auto
			width: 25%

		.row-hs-cols-md-5 > *
			flex: 0 0 auto
			width: 20%

		.row-hs-cols-md-6 > *
			flex: 0 0 auto
			width: 16.6666666667%

		.hs-col-md-auto
			flex: 0 0 auto
			width: auto

		.hs-col-md-1
			flex: 0 0 auto
			width: 8.33333333%

		.hs-col-md-2
			flex: 0 0 auto
			width: 16.66666667%

		.hs-col-md-3
			flex: 0 0 auto
			width: 25%

		.hs-col-md-4
			flex: 0 0 auto
			width: 33.33333333%

		.hs-col-md-5
			flex: 0 0 auto
			width: 41.66666667%

		.hs-col-md-6
			flex: 0 0 auto
			width: 50%

		.hs-col-md-7
			flex: 0 0 auto
			width: 58.33333333%

		.hs-col-md-8
			flex: 0 0 auto
			width: 66.66666667%

		.hs-col-md-9
			flex: 0 0 auto
			width: 75%

		.hs-col-md-10
			flex: 0 0 auto
			width: 83.33333333%

		.hs-col-md-11
			flex: 0 0 auto
			width: 91.66666667%

		.hs-col-md-12
			flex: 0 0 auto
			width: 100%

	@media (min-width: 992px)
		.hs-col-lg
			flex: 1 0 0%

		.row-hs-cols-lg-auto > *
			flex: 0 0 auto
			width: auto

		.row-hs-cols-lg-1 > *
			flex: 0 0 auto
			width: 100%

		.row-hs-cols-lg-2 > *
			flex: 0 0 auto
			width: 50%

		.row-hs-cols-lg-3 > *
			flex: 0 0 auto
			width: 33.3333333333%

		.row-hs-cols-lg-4 > *
			flex: 0 0 auto
			width: 25%

		.row-hs-cols-lg-5 > *
			flex: 0 0 auto
			width: 20%

		.row-hs-cols-lg-6 > *
			flex: 0 0 auto
			width: 16.6666666667%

		.hs-col-lg-auto
			flex: 0 0 auto
			width: auto

		.hs-col-lg-1
			flex: 0 0 auto
			width: 8.33333333%

		.hs-col-lg-2
			flex: 0 0 auto
			width: 16.66666667%

		.hs-col-lg-3
			flex: 0 0 auto
			width: 25%

		.hs-col-lg-4
			flex: 0 0 auto
			width: 33.33333333%

		.hs-col-lg-5
			flex: 0 0 auto
			width: 41.66666667%

		.hs-col-lg-6
			flex: 0 0 auto
			width: 50%

		.hs-col-lg-7
			flex: 0 0 auto
			width: 58.33333333%

		.hs-col-lg-8
			flex: 0 0 auto
			width: 66.66666667%

		.hs-col-lg-9
			flex: 0 0 auto
			width: 75%

		.hs-col-lg-10
			flex: 0 0 auto
			width: 83.33333333%

		.hs-col-lg-11
			flex: 0 0 auto
			width: 91.66666667%

		.hs-col-lg-12
			flex: 0 0 auto
			width: 100%

	@media (min-width: 1200px)
		.hs-col-xl
			flex: 1 0 0%

		.row-hs-cols-xl-auto > *
			flex: 0 0 auto
			width: auto

		.row-hs-cols-xl-1 > *
			flex: 0 0 auto
			width: 100%

		.row-hs-cols-xl-2 > *
			flex: 0 0 auto
			width: 50%

		.row-hs-cols-xl-3 > *
			flex: 0 0 auto
			width: 33.3333333333%

		.row-hs-cols-xl-4 > *
			flex: 0 0 auto
			width: 25%

		.row-hs-cols-xl-5 > *
			flex: 0 0 auto
			width: 20%

		.row-hs-cols-xl-6 > *
			flex: 0 0 auto
			width: 16.6666666667%

		.hs-col-xl-auto
			flex: 0 0 auto
			width: auto

		.hs-col-xl-1
			flex: 0 0 auto
			width: 8.33333333%

		.hs-col-xl-2
			flex: 0 0 auto
			width: 16.66666667%

		.hs-col-xl-3
			flex: 0 0 auto
			width: 25%

		.hs-col-xl-4
			flex: 0 0 auto
			width: 33.33333333%

		.hs-col-xl-5
			flex: 0 0 auto
			width: 41.66666667%

		.hs-col-xl-6
			flex: 0 0 auto
			width: 50%

		.hs-col-xl-7
			flex: 0 0 auto
			width: 58.33333333%

		.hs-col-xl-8
			flex: 0 0 auto
			width: 66.66666667%

		.hs-col-xl-9
			flex: 0 0 auto
			width: 75%

		.hs-col-xl-10
			flex: 0 0 auto
			width: 83.33333333%

		.hs-col-xl-11
			flex: 0 0 auto
			width: 91.66666667%

		.hs-col-xl-12
			flex: 0 0 auto
			width: 100%

	@media (min-width: 1400px)
		.hs-col-xxl
			flex: 1 0 0%

		.row-hs-cols-xxl-auto > *
			flex: 0 0 auto
			width: auto

		.row-hs-cols-xxl-1 > *
			flex: 0 0 auto
			width: 100%

		.row-hs-cols-xxl-2 > *
			flex: 0 0 auto
			width: 50%

		.row-hs-cols-xxl-3 > *
			flex: 0 0 auto
			width: 33.3333333333%

		.row-hs-cols-xxl-4 > *
			flex: 0 0 auto
			width: 25%

		.row-hs-cols-xxl-5 > *
			flex: 0 0 auto
			width: 20%

		.row-hs-cols-xxl-6 > *
			flex: 0 0 auto
			width: 16.6666666667%

		.hs-col-xxl-auto
			flex: 0 0 auto
			width: auto

		.hs-col-xxl-1
			flex: 0 0 auto
			width: 8.33333333%

		.hs-col-xxl-2
			flex: 0 0 auto
			width: 16.66666667%

		.hs-col-xxl-3
			flex: 0 0 auto
			width: 25%

		.hs-col-xxl-4
			flex: 0 0 auto
			width: 33.33333333%

		.hs-col-xxl-5
			flex: 0 0 auto
			width: 41.66666667%

		.hs-col-xxl-6
			flex: 0 0 auto
			width: 50%

		.hs-col-xxl-7
			flex: 0 0 auto
			width: 58.33333333%

		.hs-col-xxl-8
			flex: 0 0 auto
			width: 66.66666667%

		.hs-col-xxl-9
			flex: 0 0 auto
			width: 75%

		.hs-col-xxl-10
			flex: 0 0 auto
			width: 83.33333333%

		.hs-col-xxl-11
			flex: 0 0 auto
			width: 91.66666667%

		.hs-col-xxl-12
			flex: 0 0 auto
			width: 100%
