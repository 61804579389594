.pswp-custom-arrow-prev, .pswp-custom-arrow-next
	position: absolute
	top: 50%
	transform: translateY(-50%)
.pswp-custom-arrow-prev
	left: 0
	padding-left: 15px !important
.pswp-custom-arrow-next
	right: 0
	padding-right: 15px !important
.pswp--one-slide
	.pswp-custom-arrow-prev, .pswp-custom-arrow-next
		opacity: 0
		visibility: hidden
