.preloader
	position: fixed
	top: 0
	left: 0
	width: 100%
	height: 100%
	z-index: 600
	overflow: hidden
.preloader:not(.preloader_loaded)
	cursor: progress
	*
		cursor: progress
.preloader__wrapper
	display: flex
	flex-direction: column
	position: relative
	align-items: center
	justify-content: center
	width: 100%
	height: 100%
.preloader__content
	display: flex
	flex-direction: column
	align-items: center
	justify-content: center
	width: 100%
	height: 100%
	position: relative
.preloader__footer
	position: relative
	margin-top: auto
	width: 100%
	z-index: 50
.preloader__image
	display: inline-block
	height: 66vh
	img, video
		height: 100%
		max-height: 66vh
		width: auto
		transition: none
.preloader__image-mask
	display: inline-flex
	align-items: center
	justify-content: center
	overflow: hidden
.preloader__wrapper-image
	display: inline-flex
	align-items: center
	justify-content: center
	position: absolute
	top: 50%
	left: 50%
	max-width: calc(100% - 2 * var(--gutter-horizontal))
	max-height: 100%
	margin: auto
	transform: translate(-50%, -50%)
	z-index: 40
.preloader__wrapper-images-outer
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
.preloader__loading
	position: absolute
	bottom: 0
	left: 0
	width: 100%
	height: 100%
	display: flex
	align-items: center
	justify-content: center
.preloader__wrapper-counter
	position: absolute
	top: 0
	left: var(--gutter-horizontal)
	right: var(--gutter-horizontal)
	bottom: 0
	+fluid('width', 200, 600)
	+fluid('height', 200, 600)
	margin: auto
	aspect-ratio: 1 / 1
	border: 1px solid var(--color-border-opacity)
	border-radius: 100em
	display: flex
	align-items: center
	justify-content: center
	svg
		display: block
		position: absolute
		top: 50%
		left: 50%
		transform: translate(-50%, -50%)
		margin: auto
		width: 100%
		height: 100%
		border-radius: 100%
		stroke-width: 2px
		pointer-events: none
		stroke-dashoffset: 0.001
		stroke-dasharray: 0px, 999999px
		.circle
			stroke: currentColor
.preloader__heading
	position: absolute
	top: 50%
	left: var(--gutter-horizontal)
	right: var(--gutter-horizontal)
	transform: translateY(-50%)
	z-index: 50
.preloader__counter
	position: relative
	display: flex
	justify-content: center
	align-items: center
	white-space: nowrap
	overflow: hidden
	width: 100%
.preloader__wrapper-images-inner
	position: relative
	height: 100%
	max-width: 100%
	max-height: 100%
	overflow: hidden
.preloader__overlay
	z-index: 0
.preloader__counter-sign
	display: inline-block
	margin-top: 0.4em
.preloader__wrapper-counter-end
	position: absolute
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)
	overflow: hidden
