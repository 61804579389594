.content-box
	position: relative
	padding: 20px
	cursor: pointer
	transform: scale(1)
	+trans1	
	.title
		position: absolute
		bottom: 20px
		margin-left: 0px
		background: var(--color-dark-2)
		> *
			margin: 0
			padding: 10px 14px	
	&:hover
		transform: scale(1.05)
		+trans1	
		.button
			opacity: 1	
			+trans1	
			transform: translateY(0)
		.cls-1
			fill: var(--color-red)	
			+trans1	
		svg
			+trans1		
	.button
		opacity: 0
		+trans1
		transform: translateY(30px)
