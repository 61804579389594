.pricing-table
	display: flex
	flex-direction: column
	position: relative
	width: 100%
	height: 100%
.pricing-table_hovered
	.pricing-table__overlay
		opacity: 0.7
.pricing-table__outline, .pricing-table__border
	pointer-events: none
.pricing-table__outline
	position: absolute
	top: calc(var(--gutter-horizontal-min) * 1px)
	left: calc(var(--gutter-horizontal-min) * 1px)
	right: calc(var(--gutter-horizontal-min) * 1px)
	bottom: calc(var(--gutter-horizontal-min) * 1px)
.pricing-table__border
	position: absolute
	top: 0
	right: 0
	bottom: 0
	left: 0
.pricing-table__background
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	overflow: hidden
	z-index: 0
.pricing-table__overlay
	z-index: 10
	+trans1(opacity)
.pricing-table__content
	display: flex
	flex-direction: column
	width: 100%
	height: 100%
	position: relative
	z-index: 50

.pricing-table__currency-sign
	display: inline-block
	// +fluid-type(14, 32)
	font-size: 40%
	font-weight: bold
	margin-top: 0.33em
	margin-right: 0.15em
.pricing-table__price
	display: inline-block
.pricing-table__cost
	display: inline-flex
	justify-content: center
.pricing-table__property-item
	display: flex
	align-items: center
	justify-content: space-between
