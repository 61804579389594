.section-fullheight
	display: flex
.section-fullheight-column
	display: flex
	flex-direction: column
.section-fullheight__inner
	position: relative
	width: 100%
	//height: 100vh
	z-index: 50
	min-height: 560px


.section-fullheight__inner_mobile
	height: calc(var(--fix-bar-vh, 1vh) * 100)
	min-height: 0vh

+max-screen-md
	.section-fullheight__inner_mobile-auto
		height: auto
		display: block
		min-height: 0
	.section-fullheight__inner_mobile-min-auto
		height: auto
		display: block
		min-height: 100vh	


