.cursor-none
	cursor: none !important
	*
		cursor: none !important

.cursor-progress
	cursor: progress !important
	*
		cursor: progress !important

.pointer-events-none
	pointer-events: none !important
	*
		pointer-events: none !important
.pointer-events-inner-none
	*
		pointer-events: none !important

.pointer-events-links-none
	a
		pointer-events: !important

a.pointer-events-links-none
	pointer-events: !important
