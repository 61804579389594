.filter
	position: relative
	white-space: nowrap
.filter__inner
	margin-top: -0.5em
	margin-bottom: -0.5em
	margin-left: -0.25em
	margin-right: -0.25em
	position: relative
.filter__item
	position: relative
	padding-top: 0.5em
	padding-bottom: 0.5em
	cursor: pointer
	+trans1(color)
	&:not(.filter__item_active):hover
		color: var(--color-accent)
.filter__item-inner
	display: inline-block
	padding: 0.25em
	svg
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		stroke: currentColor
		stroke-width: 1px
		pointer-events: none
.filter__item_active
	color: var(--color-accent) !important
	pointer-events: none
.filter__item-label
	display: inline-block

+max-screen-md
	.filter
		.filter__item
			&:first-child
				padding-top: 0
			&:last-child
				padding-bottom: 0
