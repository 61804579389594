// .header__bar_sticky
// 	--header-min-gutters-vertical: 10
// 	--header-max-gutters-vertical: 20
// 	--header-min-gutters-horizontal: 20
// 	--header-max-gutters-horizontal: 40

.header__bar
	display: flex
	z-index: 500
	pointer-events: none
	transition: background-color 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease, opacity 0.3s ease, transform 0.3s ease


.header__bar_absolute
	position: absolute
	top: 0
	left: 0
	right: 0

.header__bar_fixed
	position: fixed
	top: 0
	left: 0
	right: 0

.header__bar_relative
	position: relative !important

.header__bar[data-arts-header-sticky-class]
	box-shadow: 0px 0px 30px 0px rgba(24, 24, 24, 0.09)

.opened
	.header__bar[data-arts-header-sticky-class]
		box-shadow: none

.header__bar_sticky[data-arts-header-sticky-class]:not(.header__bar_scrolling-down):not(.ajax-loading .header__bar_sticky)
	border-color: transparent
	box-shadow: 0px 0px 30px 0px rgba(24, 24, 24, 0.09)
	.header__border-horizontal
		background-color: transparent
.header__bar_scrolling-down, .ajax-loading .header__bar_sticky
	background-color: transparent !important
.header__bar_sticky
	translate: none
	rotate: none
	scale: none
	transform: translateY(var(--translateY, 0px))
	transition: background-color 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease, opacity 0.3s ease
	*
		--translateY: initial
