.widget_nav_menu
	ul.menu-column
		> li
			white-space: normal
			word-break: break-word
			a
				display: inline-block
				padding: 0
				text-transform: none
				letter-spacing: 0
		> li.menu-item-has-children:not(:last-child)
			margin-bottom: 1em !important
