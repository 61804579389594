.portfolio-card
	display: block
.portfolio-card__wrapper-media
	position: relative
	overflow: hidden
	z-index: 50
.portfolio-card__media-mask
	position: relative
	max-width: 66vw
	width: 1320px
.portfolio-card__link
	display: block
	+trans1
	&:hover
		.portfolio-card__overlay, .portfolio-card__wrapper-marquee, .portfolio-card__wrapper-content-top
			opacity: 1
			visibility: visible
.portfolio-card__link_static
	&:hover
		.portfolio-card__overlay_static
			opacity: 0.6
		.portfolio-card__wrapper-marquee_static
			opacity: 1
			color: var(--color-accent)
			*
				color: var(--color-accent)
		.portfolio-card__wrapper-marquee_mask
			opacity: 1
			color: var(--color-accent-dark-theme)
			*
				color: var(--color-accent-dark-theme)
.portfolio-card__content
	display: inline-block
.portfolio-card__wrapper-marquee
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	display: flex
	align-items: center
	z-index: 50
	opacity: 0
	visibility: hidden
	transition: color 0.3s ease, opacity 0.3s ease
.portfolio-card__wrapper-marquee_static
	opacity: 0.5
	visibility: visible
.portfolio-card__wrapper-marquee_mask
	opacity: 1
	visibility: visible
.portfolio-card__overlay
	z-index: 0
	opacity: 0
	visibility: hidden
	+trans1
.portfolio-card__overlay_static
	opacity: 1
	visibility: visible
.portfolio-card__wrapper-content-top
	position: absolute
	top: 0
	left: 0
	width: 100%
	+fluid('padding', 20, 40)
	display: flex
	justify-content: space-between
	z-index: 50
	opacity: 0
	visibility: hidden
	+trans1
.portfolio-card__wrapper-content-bottom
	position: absolute
	bottom: 0
	left: 0
	width: 100%
	+fluid('padding', 20, 40)
	display: flex
	justify-content: space-between
	z-index: 50
	opacity: 0
	visibility: hidden
	+trans1

+max-screen-lg
	.portfolio-card__media-mask
		max-width: 100%
		width: 100%
	.portfolio-card__wrapper-content-top
		padding: 20px
