.input-search__input
	padding-right: 30px !important
	&::-webkit-outer-spin-button, &::-webkit-inner-spin-button, &::-webkit-clear-button
		-webkit-appearance: none
		margin: 0
.input-search__submit
	display: inline-block
	font-size: 18px
	width: 24px
	height: 24px
	padding: 0
	position: absolute
	right: 0
	top: 12px
	bottom: 0
	margin: auto
	background: transparent
	border: none
	color: #888
	outline: none
	+trans1
	cursor: pointer
	&:hover
		color: var(--color-accent)
	&:focus
		outline: none
