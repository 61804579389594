.bg-svg-1
	z-index: -1
	+fluid('right', -400, -160)
	position: absolute
	bottom: 0

.svg-title-1
	+fluid('width', 10, 12)
	margin-right: 8px

.svg-title-2
	+fluid('margin-top', 3, 7)
	+fluid('width', 13, 16)
	margin-right: 10px

.svg-arrow
	fill: none
	stroke: #DB0011
	stroke-width: 3px



.fix-width-1
	max-width: 440px
	position: relative
	margin: 0 auto	
	+fluid('left', 0, -30)
	@media screen and (max-width: $sm)
		width: calc( 100% -  40px )
		left: 0
		margin-bottom: 50px
	


.fix-width-2
	position: relative
	margin: 0 auto	
	+fluid('max-width', 220, 500)	
	+fluid('left', -50, -80)
	@media screen and (max-width: $sm)
		left: 0px
		margin-top: 50px
		max-width: 200px



.gform_body
	input, textarea
		border: none
		border-bottom: 1px solid var(--color-dark-1)
		padding-left: 0 !important
		background-color: transparent !important
	::placeholder
		font-weight: normal	
		color: var(--color-dark-1) !important
	select	
		border: none !important
		border-bottom: 1px solid var(--color-dark-1) !important
		border-radius: 0 !important
		padding-left: 0 !important
		padding-top:  4px !important
		color: var(--color-dark-1) !important
		background-color: transparent !important
.gform_button
	border: none !important	
	padding: 4px 30px !important
	text-transform: uppercase
	font-weight: 600
	font-size: 15px
	letter-spacing: 0.5px