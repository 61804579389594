.color-accent
	color: var(--color-accent) !important
.color-accent-dark-theme
	color: var(--color-accent-dark-theme) !important
.color-accent-light-theme
	color: var(--color-accent-light-theme) !important

.color-light-theme
	color: var(--paragraph-color-light-theme) !important
.color-dark-theme
	color: var(--paragraph-color-dark-theme) !important


.color-dark-1
	color: var(--color-dark-1) !important

.color-dark-2
	color: var(--color-dark-2) !important

.color-dark-3
	color: var(--color-dark-3) !important

.color-dark-4
	color: var(--color-dark-4) !important

.color-gray-1
	color: var(--color-gray-1) !important

.color-gray-2
	color: var(--color-gray-2) !important

.color-gray-3
	color: var(--color-gray-3) !important

.color-gray-4
	color: var(--color-gray-4) !important

.color-red
	color: var(--color-red) !important
