.shape-rounded-rectangle
	overflow: hidden
	clip-path: inset(0px)
	border-radius: 200em
.shape-oval
	overflow: hidden
	clip-path: inset(0px)
	border-radius: 100%
.shape-top-arc
	overflow: hidden
	clip-path: inset(0px)
	border-top-left-radius: 200em
	border-top-right-radius: 200em
.shape-bottom-arc
	overflow: hidden
	clip-path: inset(0px)
	border-bottom-left-radius: 200em
	border-bottom-right-radius: 200em
