.mask-1
	-webkit-mask-image: url('../img/lineanegocios_imagen.svg')
	mask-image: url('../img/lineanegocios_imagen.svg')
	mask: url('../img/mask/lineanegocios_imagen.svg')
	-webkit-mask-size: ccontain
	mask-size: contain
	-webkit-mask-repeat: no-repeat
	mask-repeat: no-repeat
	-webkit-mask-position: center
	mask-position: center


.bg-mask-1
	left: 44px !important
	top: 0px !important	
	svg
		width: calc( 100% - 40px )
		.cls-1
			fill: none
			stroke: var(--color-red)
			stroke-width: 1

.mask-2
	-webkit-mask-image: url('../img/mascara_cita_imagen.svg')
	mask-image: url('../img/mascara_cita_imagen.svg')
	mask: url('../img/mask/mascara_cita_imagen.svg')
	-webkit-mask-size: ccontain
	mask-size: contain
	-webkit-mask-repeat: no-repeat
	mask-repeat: no-repeat
	-webkit-mask-position: center
	mask-position: center	
	background-image: url('../img/mask/banner-2.svg')
	margin-top: 25px

.mask-bg.bg-mask-2
	+fluid('left', 30, 60)
	top: 0px !important	
	background: url('../img/mask/mascara_cita_trazo.svg')
	background-repeat: no-repeat


.mask-bg
	width: 100%
	height: 100%
	max-width: 100%
	max-height: 100%
	background-size: contain !important
	background-repeat: no-repeat
	position: absolute
	top: 0
	left: 0
	right: 0
	bottom: 0




.mask-3
	-webkit-mask-image: url('../img/mask/banner-1.svg')
	mask-image: url('../img/mask/banner-1.svg')
	mask: url('../img/mask/banner-1.svg')
	-webkit-mask-size: ccontain
	mask-size: cover
	-webkit-mask-repeat: no-repeat
	mask-repeat: over
	-webkit-mask-position: left
	mask-position: left
	@media screen and (max-width: $xs)
		mask: none



.mask-4
	-webkit-mask-image: url('../img/mask/mascara_ventana_linea.svg')
	mask-image: url('../img/mask/mascara_ventana_linea.svg')
	mask: url('../img/mask/mascara_ventana_linea.svg')
	-webkit-mask-size: ccontain
	mask-size: cover
	-webkit-mask-repeat: no-repeat
	mask-repeat: over
	-webkit-mask-position: left
	mask-position: left

.mask-5
	-webkit-mask-image: url('../img/mask/fondo_cita.svg')
	mask-image: url('../img/mask/fondo_cita.svg')
	mask: url('../img/mask/fondo_cita.svg')
	-webkit-mask-size: ccontain
	mask-size: cover
	-webkit-mask-repeat: no-repeat
	mask-repeat: over
	-webkit-mask-position: right
	mask-position: right