#page-header, #page-wrapper, #page-footer
	select
		display: block
		border: 1px solid var(--color-border-solid)
		border-radius: 2px
		padding: 12px 36px 12px 16px
		background-color: transparent
		outline: none
		-webkit-appearance: none
		-moz-appearance: none
		appearance: none
		background-image: url('../img/ui/select_black.png')
		background-position: right 15px bottom 50%
		background-repeat: no-repeat
		background-size: 6px 4px
		color: currentColor
