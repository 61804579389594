.infinite-list
	position: relative
	width: 100%
	height: 100%
	overflow: hidden
.infinite-list_hovered, .infinite-list_hovered-zoom-out
	a.infinite-list__item-heading.current, .infinite-list__item-heading.current a
		color: var(--color-accent)
		*
			color: var(--color-accent)
.infinite-list__content
	position: absolute
	top: 50%
	transform: translateY(-50%)
	z-index: 100
	pointer-events: none
	// overflow: hidden
	a
		pointer-events: auto
.infinite-list__content_bottom
	top: auto
	bottom: var(--gutter-vertical)
	transform: translateY(0%)
.infinite-list__content_top
	top: var(--gutter-vertical)
	bottom: auto
	transform: translateY(0%)
.infinite-list__content_relative
	position: relative
	top: auto
	bottom: auto
	transform: none
.infinite-list__images
	position: relative
	width: 100%
	height: 100%
	z-index: 40
.infinite-list__images-lane
	position: absolute
	top: 0
	height: 100%
	width: 100%
	overflow: hidden
	clip-path: inset(0px)
	z-index: 10
	pointer-events: none
.infinite-list__images-lane_left
	left: 0
	right: auto
.infinite-list__images-lane_right
	left: auto
	right: 0

.infinite-list__image-link
	position: relative
	display: block
	pointer-events: auto
.infinite-list__item-heading
	display: inline-block
.infinite-list__image-item_lock-inactive:not(.active)
	pointer-events: none
	*
		pointer-events: none
.infinite-list__image-inner
	width: 100%
	// height: 100%
.infinite-list__counters
	+trans1(opacity)
	white-space: nowrap
.infinite-list__image-item_narrow-width
	width: 760px
	max-width: 66vw
	flex: 1 0 760px
.infinite-list__image-item_halfscreen-width
	width: 100%
	max-width: calc(50vw - var(--gutter-horizontal) * 2)
.infinite-list__hide-on-hover
	+trans1
	transform: translateY(0%)
	opacity: 1
	visibility: visible
.infinite-list__header
	overflow: hidden
.infinite-list__header_absolute
	position: absolute
	top: 50%
	transform: translateY(-50%)
	left: 0
	right: 0
.infinite-list__opacity-link
	+trans1
.infinite-list__marquee-row
	display: flex
	flex-wrap: wrap
.infinite-list__marquee-image-inner
	padding: max(calc(var(--gutter-horizontal) / 2), var(--gutter-horizontal-min) * 1px)

.infinite-list__wrapper
	position: relative
	width: 100%
	height: 100%
	overflow: hidden

+min-screen-md
	.infinite-list__marquee-row
		height: 100%
		padding-left: max(calc(var(--gutter-horizontal) / 2), var(--gutter-horizontal-min) * 1px)
		padding-right: max(calc(var(--gutter-horizontal) / 2), var(--gutter-horizontal-min) * 1px)

+max-screen-md
	.infinite-list__image-item_narrow-width, .infinite-list__image-item_halfscreen-width
		max-width: 50vw
