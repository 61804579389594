.slider-arrows
	position: absolute
	margin: -8px
.slider-arrows_left-bottom
	left: var(--gutter-horizontal)
	bottom: var(--gutter-vertical)
.slider-arrows_right-bottom
	right: var(--gutter-horizontal)
	bottom: var(--gutter-vertical)
.slider-arrows_center-bottom
	left: var(--gutter-horizontal)
	bottom: var(--gutter-vertical)
	right: var(--gutter-horizontal)
	margin: auto
	text-align: center

.slider-arrows__arrow
	margin: 8px
