/* Typography */
\:root
	--font-primary: 'Titillium Web', sans-serif
	--font-secondary: 'Titillium Web', sans-serif


	/* Paragraph */
	--body-paragraph-font-family: var(--font-primary)
	--body-paragraph-min-font-size: 16
	--body-paragraph-max-font-size: 18
	--body-paragraph-line-height: 1.6
	--body-paragraph-letter-spacing: 0px
	--body-paragraph-color-light-theme: #0E1E2E
	--body-paragraph-color-dark-theme: #ffffff
	--body-paragraph-color: var(--paragraph-color-light-theme)

	--paragraph-font-family: var(--font-primary)
	--paragraph-min-font-size: 18
	--paragraph-max-font-size: 20
	--paragraph-min-line-height: 26
	--paragraph-max-line-height: 30
	--paragraph-letter-spacing: -0px
	--paragraph-color-light-theme: #0E1E2E
	--paragraph-color-dark-theme: #ffffff
	--paragraph-color: var(--paragraph-color-light-theme)

	/* Buttons */
	--ui-element-font-family: var(--font-primary)
	--ui-element-min-font-size: 14
	--ui-element-max-font-size: 16
	--ui-element-font-weight: 600
	--ui-element-line-height: 1
	--ui-element-letter-spacing: 0
	--ui-element-color-light-theme: #0E1E2E
	--ui-element-color-dark-theme: #fff
	--ui-element-color: var(--ui-element-color-light-theme)

	/* Display XXL *
	--display-xxl-font-family: var(--font-secondary)
	--display-xxl-max-font-size: 86
	--display-xxl-min-font-size: 40
	--display-xxl-font-weight: 200
	--display-xxl-line-height: 1.2
	--display-xxl-letter-spacing: -1.5px
	--display-xxl-color-light-theme: #0E1E2E
	--display-xxl-color-dark-theme: #ffffff
	--display-xxl-color: var(--display-xxl-color-light-theme)

	/* Display caps */
	--display-caps-font-family: var(--font-secondary)
	--display-caps-max-font-size: 144
	--display-caps-min-font-size: 48
	--display-caps-font-weight: 200
	--display-caps-line-height: 1.2
	--display-caps-letter-spacing: 0.06em
	--display-caps-color-light-theme: #0E1E2E
	--display-caps-color-dark-theme: #ffffff
	--display-caps-color: var(--display-caps-color-light-theme)

	/* Display XL *
	--display-xl-font-family: var(--font-secondary)
	--display-xl-max-font-size: 60
	--display-xl-min-font-size: 48
	--display-xl-font-weight: 300
	--display-xl-line-height: 1.1
	--display-xl-letter-spacing: -1px
	--display-xl-color-light-theme: #0E1E2E
	--display-xl-color-dark-theme: #ffffff
	--display-xl-color: var(--display-xl-color-light-theme)

	/* Heading 1 */
	--h1-font-family: var(--font-secondary)
	--h1-max-font-size: 40
	--h1-min-font-size: 30
	--h1-font-weight: 400
	--h1-line-height: 1.25
	--h1-letter-spacing: 0.5px
	--h1-color-light-theme: #0E1E2E
	--h1-color-dark-theme: #ffffff
	--h1-color: var(--h1-color-light-theme)

	/* Heading 2 */
	--h2-font-family: var(--font-secondary)
	--h2-max-font-size: 32
	--h2-min-font-size: 28
	--h2-font-weight: 500
	--h2-line-height: 1.3
	--h2-letter-spacing: -0.5px
	--h2-color-light-theme: #0E1E2E
	--h2-color-dark-theme: #ffffff
	--h2-color: var(--h2-color-light-theme)

	/* Heading 3 */
	--h3-font-family: var(--font-secondary)
	--h3-max-font-size: 26
	--h3-min-font-size: 22
	--h3-font-weight: 400
	--h3-line-height: 1.3
	--h3-letter-spacing: -0.5px
	--h3-color-light-theme: #0E1E2E
	--h3-color-dark-theme: #ffffff
	--h3-color: var(--h3-color-light-theme)

	/* Heading 4 */
	--h4-font-family: var(--font-secondary)
	--h4-max-font-size: 22
	--h4-min-font-size: 20
	--h4-font-weight: 300
	--h4-line-height: 1.4
	--h4-letter-spacing: 0px
	--h4-color-light-theme: #0E1E2E
	--h4-color-dark-theme: #ffffff
	--h4-color: var(--h4-color-light-theme)

	/* Heading 5 */
	--h5-font-family: var(--font-primary)
	--h5-max-font-size: 20
	--h5-min-font-size: 17
	--h5-font-weight: 700
	--h5-line-height: 1.5
	--h5-letter-spacing: 1px
	--h5-color-light-theme: #0E1E2E
	--h5-color-dark-theme: #ffffff
	--h5-color: var(--h5-color-light-theme)

	/* Heading 6 */
	--h6-font-family: var(--font-primary)
	--h6-max-font-size: 18
	--h6-min-font-size: 16
	--h6-font-weight: 600
	--h6-line-height: 1.3
	--h6-letter-spacing: 0.5px
	--h6-color-light-theme: #0E1E2E
	--h6-color-dark-theme: #ffffff
	--h6-color: var(--h6-color-light-theme)

	/* Blockquote */
	--blockquote-font-family: var(--font-primary)
	--blockquote-max-font-size: 24
	--blockquote-min-font-size: 18
	--blockquote-font-weight: 400
	--blockquote-line-height: 1.33
	--blockquote-letter-spacing: 0
	--blockquote-color-light-theme: #0E1E2E
	--blockquote-color-dark-theme: #ffffff
	--blockquote-color: var(--blockquote-color-light-theme)

	/* Dropcap */
	--dropcap-font-family: var(--font-secondary)
	--dropcap-max-font-size: 112
	--dropcap-min-font-size: 52
	--dropcap-font-weight: 200
	--dropcap-line-height: 0.7
	--dropcap-letter-spacing: 0
	--dropcap-color-light-theme: #0E1E2E
	--dropcap-color-dark-theme: #ffffff
	--dropcap-color: var(--dropcap-color-light-theme)

	/* Subheading */
	--subheading-font-family: var(--font-primary)
	--subheading-max-font-size: 18m
	--subheading-min-font-size: 16
	--subheading-font-weight: 700
	--subheading-line-height: 1.3
	--subheading-letter-spacing: 0em
	--subheading-color-light-theme: #0E1E2E
	--subheading-color-dark-theme: #ffffff
	--subheading-color: var(--subheading-color-light-theme)

	/* Super label */
	--super-label-font-family: var(--font-primary)
	--super-label-max-font-size: 15
	--super-label-min-font-size: 13
	--super-label-font-weight: 700
	--super-label-line-height: 1
	--super-label-letter-spacing: 0.5px
	--super-label-color-light-theme: #0E1E2E
	--super-label-color-dark-theme: #ffffff
	--super-label-color: var(--super-label-color-light-theme)

/* Distances */
\:root
	/* Fluid paddings & margins (min values) */
	--distance-min-xxsmall: 20
	--distance-min-xsmall: 30
	--distance-min-small: 40
	--distance-min-medium: 70
	--distance-min-large: 80
	--distance-min-xlarge: 130

	/* Fluid paddings & margins (max values) */
	--distance-max-xxsmall: 30
	--distance-max-xsmall: 40
	--distance-max-small: 80
	--distance-max-medium: 100
	--distance-max-large: 140
	--distance-max-xlarge: 150

	/* Container & page gutters */
	--gutter-horizontal-min: 20
	--gutter-horizontal-max: 80
	--gutter-vertical-min: 20
	--gutter-vertical-max: 80
	+fluid(--gutter-horizontal, var(--gutter-horizontal-min), var(--gutter-horizontal-max))
	+fluid(--gutter-vertical, var(--gutter-vertical-min), var(--gutter-vertical-max))

	/* Bootstrap */
	--bs-gutter-x: 20px
	--bs-gutter-y: 20px

/* Colors */
\:root

	/* Dark */
	--color-dark-1: #000

	--color-dark-2: #1F1F1F

	--color-dark-3: #FF6D66

	--color-dark-4: #0E1E2E

	/* Gray colors */
	--color-gray-1: #F2F2F2
	--color-gray-1-rgb: 147, 149, 151

	--color-gray-2: #C0C0C0
	--color-gray-2-rgb: 192, 192, 192

	--color-gray-3: #777777
	--color-gray-3-rgb: 119, 119, 119

	--color-gray-4: #888888
	--color-gray-4-rgb: 136, 136, 136

	/* Light colors */
	--color-light-1: #FFFFFF

	--color-light-2: #e5ecee

	--color-light-3: #E8E6E0

	--color-light-4: #F1F2ED

	/* Colors others */
	--color-red: #DB0011

	/* Accent */
	--color-accent-light-theme: #FF6D66
	--color-accent-light-theme-rgb: 101, 100, 83

	--color-accent-dark-theme: #FF6D66
	--color-accent-dark-theme-rgb: 253, 249, 207

	--color-accent: var(--color-accent-light-theme)
	--color-accent-rgb: 101, 100, 83

	/* Border */
	--color-border-opacity-light-theme: #FF6D66
	--color-border-opacity-dark-theme: rgba(255, 255, 255, .2)
	--color-border-opacity: var(--color-border-opacity-light-theme)

	--color-border-solid-light-theme: #C0C0C0
	--color-border-solid-dark-theme: #fff
	--color-border-solid: var(--color-border-solid-light-theme)

	/* Links */
	--color-links-light-theme: #0E1E2E
	--color-links-dark-theme: #fff
	--color-links: var(--color-links-light-theme)

/* Header */
\:root
	/* Logo */
	--logo-max-width: 210
	--logo-min-width: 190
	/* Menu  */
	--menu-font-weight: 600
	--menu-letter-spacing: 0.5px
	--menu-font-family: var(--font-primary)
	--menu-max-font-size: 16
	--menu-min-font-size: 14
	--menu-max-margin-right: 60
	--menu-min-margin-right: 5
	--menu-font-text-transform: uppercase
	--color-menu-links-light-theme: #0E1E2E
	--color-menu-links-dark-theme: #fff
	--color-menu-links: var(--color-links-light-theme)

	/* Submenu */
	--color-classic-submenu-links: #0E1E2E
	--color-classic-submenu-links-hover: #656453
	--color-classic-submenu-links-hover-background: #65645315

	/* Overlay menu item */
	--overlay-menu-item-font-family: var(--font-secondary)
	--overlay-menu-item-max-font-size: 40
	--overlay-menu-item-min-font-size: 30
	--overlay-menu-item-font-weight: 400
	--overlay-menu-item-line-height: 1.2
	--overlay-menu-item-letter-spacing: -0.5px
	--overlay-menu-item-color-light-theme: #0E1E2E
	--overlay-menu-item-color-dark-theme: #ffffff
	--overlay-menu-item-color: var(--overlay-menu-item-color-light-theme)

	/* Overlay submenu item */
	--overlay-submenu-item-font-family: var(--font-secondary)
	--overlay-submenu-item-max-font-size: 40
	--overlay-submenu-item-min-font-size: 30
	--overlay-submenu-item-font-weight: 300
	--overlay-submenu-item-line-height: 1.2
	--overlay-submenu-item-letter-spacing: -0.5px
	--overlay-submenu-item-color-light-theme: #0E1E2E
	--overlay-submenu-item-color-dark-theme: #ffffff
	--overlay-submenu-item-color: var(--overlay-submenu-item-color-light-theme)


	
