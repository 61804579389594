.blocking-curtain
	position: fixed
	top: 0
	left: 0
	right: 0
	bottom: 0
	z-index: 999
	display: none
[data-barba="wrapper"]
	height: 100%
	overflow: hidden
.page-wrapper
	+ .page-wrapper
		position: fixed
		top: 0
		left: 0
		right: 0
		height: 100vh
		z-index: -1
		.page-wrapper__content
			opacity: 0
