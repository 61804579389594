.counter-up
	display: inline-block
.counter-up__prefix
	font-size: 75%
.counter-up__suffix
	font-size: 75%
.counter-up__number
	line-height: 0.9 !important
	white-space: nowrap
.counter-up__label
	line-height: 1 !important
