.auto-scroll-next_complete
	.auto-scroll-next__label
		opacity: 0
		visibility: hidden
	.auto-scroll-next__button-normal
		transform: scale(0)
	.auto-scroll-next__button-hover
		transform: translate(-50%, -50%) scale(0)
	.auto-scroll-next__lane
		opacity: 0
		visibility: hidden
