.auto-scroll-next_scrolling
	.auto-scroll-next__label-lane-normal
		transform: translateY(100%)
	.auto-scroll-next__label-lane-scrolling
		transform: translateY(0%)

.auto-scroll-next__label-lane-normal
	transition: transform 0.6s ease
.auto-scroll-next__label-lane-scrolling
	transition: transform 0.6s ease
	transform: translateY(-100%)
