.pswp-custom-button_zoom
	+trans1(opacity)
	opacity: 0
	visibility: hidden
.pswp-custom-button__zoom-icon
	display: flex
	align-items: center
	justify-content: center
	width: 40px
	height: 40px
.pswp--zoom-allowed
	.pswp-custom-button_zoom
		opacity: 1
		visibility: visible
