.footer__column-pt-small
	+fluid('padding-top', 20, 30)

.footer__column-pt-medium
	+fluid('padding-top', 30, 50)

.footer__row-pb-small
	+fluid('padding-bottom', 20, 30)

.footer__row-pb-medium
	+fluid('padding-bottom', 30, 50)
